<template lang="pug">
  header.header
    .header__wrapper.container
      button.header__menu-button.js-nav-open(type="button") <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 8h14M5 12h14M5 16h14" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
      a.header__logo(@click="goRoot")
        include ../assets/images/logo_title.svg
      nav.nav(id="nav")
        ul
          router-link(
            :to="{ name: 'About' }"
            v-slot="{ isExactActive }")
            li(:class="isExactActive ? 'active' : ''")
              a(@click="goAbout")  О сервисе
          router-link(
            :to="{ name: 'Blog' }"
            v-slot="{ isActive }")
            li(:class="isActive ? 'active' : ''")
              a(@click="goBlog")  Блог
          router-link(
            :to="{ name: 'PaymentPlans' }"
            v-slot="{ isExactActive }")
            li(:class="isExactActive ? 'active' : ''")
              a(@click="goPaymentPlans")  Тарифы

      .header__buttons
        template(v-if="signed")
          a.header__go-docs(@click="goDocs") Мои расчеты
          a.header__user(@click="goDocs") <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 19v-1.56c0-.82-.32-1.61-.88-2.2a2.95 2.95 0 00-2.12-.9H9c-.8 0-1.56.32-2.12.9a3.17 3.17 0 00-.88 2.2V19h12zM15 8.11a3.06 3.06 0 01-3 3.11c-1.66 0-3-1.4-3-3.1A3.06 3.06 0 0112 5c1.66 0 3 1.4 3 3.11z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>

        template(v-else)
          a.header__login(@click="goSignIn") Войти
          a.header__register(@click="goSignUp") Регистрация
          a.header__user(@click="goSignUp") <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 19v-1.56c0-.82-.32-1.61-.88-2.2a2.95 2.95 0 00-2.12-.9H9c-.8 0-1.56.32-2.12.9a3.17 3.17 0 00-.88 2.2V19h12zM15 8.11a3.06 3.06 0 01-3 3.11c-1.66 0-3-1.4-3-3.1A3.06 3.06 0 0112 5c1.66 0 3 1.4 3 3.11z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
</template>

<script>
import navigationMixin from 'mixins/navigation'
import Cookies from 'js-cookie'

export default {
  name: 'MenuHeader',
  mixins: [navigationMixin],
  data () {
    return {
      isActive: false
    }
  },
  computed: {
    signed () {
      return !!Cookies.get(process.env.VUE_APP_COOKIE_CRED_NAME) || !!Cookies.get(process.env.VUE_APP_COOKIE_AUTH_TAG)
    }
  },
  mounted () {
    const navButtons = document.querySelectorAll('.js-nav-open')
    navButtons.forEach((navButton) => {
      navButton.onclick = function (e) {
        const nav = document.getElementById('nav')
        nav.classList.toggle('active')
      }
    })
  }
}
</script>
