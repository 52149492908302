<template lang="pug">
  footer.footer
    .container
      template(v-if="showCookie")
        .cookie
          .cookie-text Мы используем &nbsp;
           span(style="text-decoration: underline")
             | файлы cookie
           |  чтобы улучшить сайт для вас
          button.cookie-btn(type="button" @click="acceptCookie") Cогласен
      .footer__grid
        a.footer__logo(@click="goRoot")
          include ../assets/images/logo_title.svg

        ul.footer__nav
          li
            a(@click="goAbout") О сервисе
          li
            a(@click="goBlog") Блог
          //li
            a(@click="goPaymentPlans") Тарифы
          li
            a(href="/offer.pdf" target="_blank") Оферта
          li
            a(href="/policy.pdf" target="_blank") Политика конфиденциальности

        address.footer__contacts
          div.footer__contacts-title Контакты

          a(href="mailto:support@heatingcalculator.online") support@heatingcalculator.online

        button.footer__scrolltop(type="button" @click="scrollTop").
          <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6 1.41 1.41z"/></svg>

        .footer__copyright Copyright © Спроектируй.рф 2021 Все права защищены
</template>

<script>
import navigation from 'mixins/navigation'
import Cookies from 'js-cookie'

export default {
  name: 'Footer',
  mixins: [navigation],
  data () {
    return {
      showCookie: false
    }
  },
  computed: {
    cookies () {
      return Cookies
    },
    accepted () {
      return this.cookies.get(process.env.VUE_APP_COOKIE_SITE) === 'accepted'
    }
  },
  mounted () {
    setTimeout(() => (this.showCookie = !this.accepted), 1000)
  },
  methods: {
    scrollTop () {
      scroll({
        top: 0,
        behavior: 'smooth'
      })
    },
    acceptCookie () {
      this.cookies.set(process.env.VUE_APP_COOKIE_SITE, 'accepted', { sameSite: 'lax' })
      this.showCookie = false
    }
  }
}
</script>
